import classNames from "clsx";
import { useTranslations, useFormatter } from "next-intl";
import { useMemo } from "react";
import { HiClock, HiCalendar } from "react-icons/hi";

import { Text, TextProps } from "@shared/elements";

import { MESSAGE_PREFIX } from "../constants";
import TinyTimeRelative, { TimeUnit } from "../TinyTimeRelative";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;
const YEAR = DAY * 364;

type DeadlineProps = {
  date: Date;
  className?: string;
  color?: TextProps["color"];
};

export default function Deadline({ date, className, color }: DeadlineProps) {
  const t = useTranslations(`${MESSAGE_PREFIX}Deadline`);
  const format = useFormatter();
  const timeDiff = date.valueOf() - Date.now();
  const timeUnits: TimeUnit[] = useMemo(
    () => [
      { unit: t("now") as string, valueMs: 20 * SECOND },
      {
        unit: (value) => t("seconds", { value }) as string,
        valueMs: SECOND,
        threshold: 45,
      },
      {
        unit: (value) => t("minutes", { value }) as string,
        valueMs: MINUTE,
        threshold: 50,
      },
      {
        unit: (value) => t("hours", { value }) as string,
        valueMs: HOUR,
        threshold: 48,
      },
      {
        unit: (value) => t("days", { value }) as string,
        valueMs: DAY,
        threshold: 8,
      },
      {
        unit: (value) => t("weeks", { value }) as string,
        valueMs: 7 * DAY,
        threshold: 4,
      },
      {
        unit: (value) => t("months", { value }) as string,
        valueMs: MONTH,
        threshold: 12,
      },
      {
        unit: (value) => t("years", { value }) as string,
        valueMs: YEAR,
      },
    ],
    [t],
  );
  const timeUnitsClosed: TimeUnit[] = useMemo(
    () => [
      { unit: t("closed.now") as string, valueMs: 20 * SECOND },
      {
        unit: (value) => t("closed.seconds", { value }) as string,
        valueMs: SECOND,
        threshold: 45,
      },
      {
        unit: (value) => t("closed.minutes", { value }) as string,
        valueMs: MINUTE,
        threshold: 50,
      },
      {
        unit: (value) => t("closed.hours", { value }) as string,
        valueMs: HOUR,
        threshold: 48,
      },
      {
        unit: (value) => t("closed.days", { value }) as string,
        valueMs: DAY,
        threshold: 8,
      },
      {
        unit: (value) => t("closed.weeks", { value }) as string,
        valueMs: 7 * DAY,
        threshold: 4,
      },
      {
        unit: (value) => t("closed.months", { value }) as string,
        valueMs: MONTH,
        threshold: 12,
      },
      {
        unit: (value) => t("closed.years", { value }) as string,
        valueMs: YEAR,
      },
    ],
    [t],
  );

  const countdownColor = useMemo(() => {
    if (timeDiff < 12 * HOUR) return "danger";
    if (timeDiff < 2 * DAY) return "warning";
    return color || "muted";
  }, [timeDiff, color]);

  const isPastDate = (date: Date) => date < new Date(Date.now());

  return (
    <Text
      size="sm"
      weight="medium"
      color={countdownColor}
      className={classNames("flex group items-center", className)}
    >
      <time
        dateTime={date.toISOString()}
        data-testid="date-relative"
        className={"group-hover:hidden inline-flex items-center"}
      >
        <HiClock className="mr-1 inline-block" size="1rem" />
        {isPastDate(date) ? (
          <TinyTimeRelative
            from={date}
            to={new Date()}
            timeUnits={timeUnitsClosed}
          />
        ) : (
          <TinyTimeRelative to={date} timeUnits={timeUnits} />
        )}
      </time>
      <time
        dateTime={date.toISOString()}
        data-testid="date-absolute"
        className={"group-hover:inline-flex items-center hidden"}
      >
        <HiCalendar className="mr-1 inline-block" size="1rem" />
        {format.dateTime(date, {
          dateStyle: "medium",
          timeStyle: "short",
        })}
      </time>
    </Text>
  );
}

Deadline.messages = [`${MESSAGE_PREFIX}Deadline`];
